import React, {useState} from 'react';
import SharpayLogin from '../components/SharpayLogin';
import SharpayAccounts from '../components/SharpayAccounts';
import {vars} from "../utils/variables";
import '../styles/Payment.scss';

const {VIEW: {MOBILE}} = vars;

const getAccountsView = (setLogin, isMobile, order, history) => {
	return <SharpayAccounts handleLogin={setLogin} history={history} isMobile={isMobile} order={order}/>;

	/*return isMobile ? (
		<div>mobile</div>
	) : <SharpayAccounts isMobile={isMobile} order={order}/>*/
};

const Payment = (props) => {
	const {screenSize, order, history} = props;
	const isMobile = screenSize === MOBILE;
	const isSavedInLocalStorage = !!(window.localStorage.getItem('user'));
	const [isLogin, setLogin] = useState(isSavedInLocalStorage);
	// const [isLogin, setLogin] = useState(true);

	const handleLogin = (value) => {
		setLogin(value);
		window.localStorage.setItem('user', 'login');
	};

	return (
		<div className={'payment-wrapper'}>
			{isLogin ? getAccountsView(setLogin, isMobile, order, history) : (
				<SharpayLogin isMobile={isMobile} handleLogin={handleLogin} order={order} history={history}/>
			)}
		</div>
	);
};

export default Payment;
