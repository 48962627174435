import React, {useState} from 'react';
import Select from 'react-select';
import {selectStyles} from '../variables';

const createMonthType = () => {
  let monthList = [];
  for (let i = 1; i <= 12; i++) {
    let title = String(i);
    monthList.push({
      value: i,
      label: title.length === 1 ? `0${title}` : title,
    });
  }
  return monthList;
};

const createYearType = () => {
  let yearList = [];
  const date = new Date();
  const startYear = date.getFullYear();
  const stopYear = startYear + 15;
  for (let i = startYear; i <= stopYear; i++) {
    yearList.push({
      value: i,
      label: String(i),
    });
  }
  return yearList;
};

const getOptions = (type) => {
  switch (type) {
    case 'month':
      return createMonthType();
    case 'year':
      return createYearType();
    default:
      return [];
  }
};

export default function useSelect({type, placeholder = '', autocomplete = 'off'}) {
  const options = getOptions(type);
  const [selectedOption, setSelectedOption] = useState(null);

  const selectInput = (
    <Select
      options={options}
      styles={selectStyles}
      value={selectedOption}
      onChange={setSelectedOption}
      placeholder={placeholder}
      inputId={`react-select-${type}`} // Set a custom input ID
    />
  );

  return [selectedOption && selectedOption.label, selectInput];
}
