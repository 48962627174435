import React, {useEffect, useState} from 'react';
import useInput from '../utils/customHooks/useInput';
import {checkOrder, sendRequest, notify} from '../utils/functions';
import {useTranslation} from 'react-i18next';
import classNames from 'classnames';
import Loader from 'react-loader-spinner';
import Summary from './Summary';

import {vars} from '../utils/variables';
import '../styles/SharpayAccounts.scss';

const {SUCCESS_API_CODE} = vars;

const SharpayAccounts = (props) => {
	const {
		order: {
			Urlid,
			CurrencyID,
			CurrencySymbol,
		},
		isMobile,
		history,
		handleLogin,
	} = props;
  const {t} = useTranslation();
	const [accounts, setAccounts] = useState([]);
	const [summary, setSummary] = useState(null);
	const [recheckedOrder, setRecheckedOrder] = useState(null);
	const [isExchangeVisible, setIsExchangeVisible] = useState(false);
	const [selectedAccount, setSelectedAccount] = useState(null);
	const [disabled, setDisabled] = useState(true);
	const [isSentOpt] = useState(false);
	const [loading, setLoading] = useState(false);

	const goBack = () => {
		history.push(`/checkout/list/${Urlid}`);
	};

	const [otp, otpInput, setValue] = useInput({
		type: 'password',
		id: 'otp',
		name: 'otp',
		placeholder: 'OTP or SMS code',
	});

	useEffect(() => {
		if (otp) {
			setDisabled(false);
		} else {
			setDisabled(true);
		}
	}, [otp]);

	useEffect(() => {
		if (summary && summary.IsCheckOtp) {
			setDisabled(true);
		} else {
			setDisabled(false);
		}

		const recheckOrder = async () => {
			await checkOrder(Urlid, setRecheckedOrder);
		};

		recheckOrder().catch((e) => {
			console.log(e);
		});
	}, [summary]);

	useEffect(() => {
		const getAccounts = async () => {
			const response = await sendRequest('CheckoutPayment/GetAccounts', {
				Params: {
					Urlid: Urlid
				}
			});

			// order['ResponseCode'] === SUCCESS_API_CODE
			if (response && response['ResponseCode'] === SUCCESS_API_CODE) {
				setAccounts(response['Response']['Account']);
			} else if (response && response['ResponseCode'] === '401') {
				handleLogin(false);
			} else {
				notify(response['ResponseText']);
			}
		};

		getAccounts().catch((e) => {
			console.log(e);
		});
	}, []);

	const checkoutPayment = async () => {
		await setLoading(true);

		try {
			const checkout = await sendRequest('CheckoutPayment/PayoutOnIncome', {
				Params: {
					Urlid: Urlid,
					AccountID: selectedAccount.ID,
				}
			});

			if (checkout && checkout['ResponseCode'] === SUCCESS_API_CODE) {
				setSummary(checkout['Response']);
			} else if (checkout && checkout['ResponseCode'] === '401') {
				handleLogin(false);
			} else {
				notify(checkout['ResponseText']);
			}
		} finally {
			await setLoading(false);
		}
	};

	const resendOtp = async () => {
		await setValue('');
		await setLoading(true);

		try {
			const otpResponse = await sendRequest('CheckoutPayment/ResendOTP', {
				Params: {
					PaymentID: parseInt(summary.ID, 10),
				}
			});

			if (otpResponse && otpResponse['ResponseCode'] === SUCCESS_API_CODE) {
				console.log('otp sent');
			} else {
				notify(otpResponse['ResponseText']);
			}
		} finally {
			setLoading(false);
		}
	};

	const confirmPayment = async () => {
		await setLoading(true);

		try {
			const confirmResponse = await sendRequest('CheckoutPayment/Confirm', {
				Params: {
					PaymentID: parseInt(summary.ID, 10),
					Code: otp,
				}
			});

			if (confirmResponse && confirmResponse['ResponseCode'] === SUCCESS_API_CODE) {
				console.log('payment confirmed');
				history.push(`/checkout/status/${Urlid}`);
			} else {
				notify(confirmResponse['ResponseText']);
			}
		} finally {
			setLoading(false);
		}
	};

	const onChangeValue = (event) => {
		const ID = event.target.value;
		const selectedAccount = accounts.filter(account => account.ID === parseInt(ID, 10))[0];

		if (CurrencyID !== selectedAccount.CurrencyID) {
			setIsExchangeVisible(true);
		} else {
			setIsExchangeVisible(false);
		}

		setSelectedAccount(selectedAccount);
	};

	const positiveBalance = !!(selectedAccount && selectedAccount.OrderAmount <= selectedAccount.Balance);

	const otpButtonClass = isSentOpt ? 'resend-otp resent' : 'resend-otp';

	return loading ? (
		<div className={'loader-wrapper'}>
			<Loader
				type="Oval"
				color="#4cd964"
				height={100}
				width={100}
			/>
		</div>
	) : (
		<div className={'sharpay-payment'}>
			{!summary ? (
				<div className={'accounts-wrapper'}>
					<div className={'accounts-head'}>
						<div className={'accounts-title'}>
              {`${t('account_selection')}:`}
						</div>
						<div className={'accounts-exchange'}>
							{isExchangeVisible && `${t('rate')} 1.00 ${CurrencySymbol} = ${selectedAccount.CurrencySymbol} ${selectedAccount.CurrencyRate} - ${t('to_pay')}: ${selectedAccount.OrderAmount}`}
						</div>
					</div>
					<div onChange={onChangeValue} className={'accounts'}>
						{accounts.map(({
														 Balance,
														 Code,
														 CurrencyCode,
														 CurrencySymbol,
														 ID,
													 }) => {
							return (
								<div key={ID} className={'acc-wrap'}>
									<div className={classNames('account', {
										'selected-account': selectedAccount && selectedAccount.ID === ID
									})}>
										<div className="form_radio">
											<input id={`radio-${ID}`} type="radio" name="radio" value={ID}/>
											<label htmlFor={`radio-${ID}`}>
												{!isMobile && (
													<img className={'account-icon'} src="/img/SharPay-Label.svg" alt=""/>
												)}
												<span className={'account-number'}>{Code}</span>
												<div className={'account-currency'}>{`${CurrencyCode} ${CurrencySymbol}`}</div>
												<div className={'account-balance'}>
													<span className={'account-balance-text'}>{`${t('balance')}:`}</span>
													<span>{Balance}</span>
												</div>
											</label>
										</div>
									</div>
								</div>
							);
						})}
					</div>
					<div className={classNames('accounts-buttons', {
						'mobile-buttons': isMobile
					})}>
						<button
							onClick={goBack}
						>
							{t('cancel')}
						</button>
						<button
							disabled={!(selectedAccount && positiveBalance)}
							className={!(selectedAccount && positiveBalance) ? 'button-disabled' : 'button-enabled'}
							onClick={checkoutPayment}
						>
							{t('next')}
						</button>
					</div>
				</div>
			) : (
				<div className={'accounts-wrapper'}>
					<div className={'accounts-head'}>
						<div className={'accounts-title'}>
              {`${t('payment_confirmation')}:`}
						</div>
					</div>
					<Summary
						PaymentForeignAmount={recheckedOrder.PaymentForeignAmount}
						TotalAmount={recheckedOrder.PaymentAmount}
						FeeAmount={recheckedOrder.PaymentFeeAmount}
						CurrencyRate={recheckedOrder.PaymentCurrencyRate}
						PaymentSystemTypeID={recheckedOrder.PaymentSystemTypeID}
						PaymentSystemTypeName={recheckedOrder.PaymentSystemTypeName}
					/>
					{summary && !!summary.IsCheckOtp && (
						<div className={'summary-otp-wrapper'}>
							<div className={'summary-otp'}>
								{otpInput}
								<div
									onClick={resendOtp}
									className={otpButtonClass}
								/>
							</div>
						</div>
					)}
					<div className={classNames('accounts-buttons', {
						'mobile-buttons': isMobile
					})}>
						<button
							onClick={goBack}
						>
              {t('cancel')}
						</button>
						<button
							disabled={disabled}
							className={disabled ? 'button-disabled' : 'button-enabled'}
							onClick={confirmPayment}
						>
              {t('next')}
						</button>
					</div>
				</div>
			)}
		</div>
	);
};

export default SharpayAccounts;
