import React from 'react';
import Content from '../components/Content';

const Checkout = (props) => {
	return props.paymentSystemTypes ? (
		<Content {...props}/>
	) : null;
};

export default Checkout;
