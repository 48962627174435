import React from 'react';
import {getMethodIconById} from '../utils/functions';
import {useTranslation} from 'react-i18next';
import {vars} from '../utils/variables';

import '../styles/Summary.scss';

const {PAYMENT_SYSTEM_TYPE_ID: {SHARPAY, CRYPTO}} = vars;

const Summary = (props) => {
	const {
		Payments,
		TotalAmount,
		FeeAmount,
		CurrencyRate,
		PaymentForeignAmount,
		PaymentSystemTypeID,
		PaymentSystemTypeName,
		BaseStatusText,
		InfoUrlText,
		InfoUrl,
		border = '2px solid #f3f3f7'
	} = props;
  const {t} = useTranslation();
	const iconPath = getMethodIconById(PaymentSystemTypeID);

	return (
		<div className={'summary-wrapper'}>
			<div className={'summary-inner'} style={{
				border: border
			}}>
				<div className={'summary-head'} style={{
					borderBottom: border
				}}>
					<img src={iconPath} alt=""/>
					<span>{PaymentSystemTypeName}</span>
				</div>
				{parseInt(PaymentSystemTypeID) === SHARPAY ? (
					<div className={'summary-details'}>
						<div className={'summary-details-item'}>
							<span className={'summary-details-title'}>{`${t('amount')}: `}</span>
							<span>{PaymentForeignAmount}</span>
						</div>
						<div className={'summary-details-item'}>
							<span className={'summary-details-title'}>{`${t('fee')}: `}</span>
							<span>{FeeAmount}</span>
						</div>
						<div className={'summary-details-item'}>
							<span className={'summary-details-title'}>{`${t('rate')}: `}</span>
							<span>{CurrencyRate}</span>
						</div>
						<div className={'summary-details-item'}>
							<span className={'summary-details-title'}>{`${t('to_pay')}: `}</span>
							<span>{TotalAmount}</span>
						</div>
					</div>
				) : (parseInt(PaymentSystemTypeID) === CRYPTO && Array.isArray(Payments)) ? (
					<div className={'summary-details'}>
						<div className={'summary-details-item'}>
							<span className={'summary-details-title'}>{`${t('amount')}: `}</span>
							<div className={'summary-details-summary'}>
								{
									Payments.map((item, index) => (
										<span key={index}>
											{`${item.Date.replace('T', ' ')}, ${item.BlockchainCode}, ${item.CurrencySymbol}, ${item.Amount}`}
										</span>
									))
								}
							</div>
						</div>
					</div>
				) : (
					<div className={'summary-details-text'}>
						<div className={'summary-details-text-container'}>
							<div className={'summary-details-text-inner'}>
                {BaseStatusText}
              </div>
						</div>
						<div className={'summary-details-text-link'}>
							<a href={InfoUrl || '#'} target="_blank" rel={'noopener noreferrer'}>
								{InfoUrlText}
							</a>
						</div>
					</div>
				)}
			</div>
		</div>
	);
};

export default Summary;
