import React, {useEffect, useState} from 'react';
import useInput from '../utils/customHooks/useInput';
import useCardInput from '../utils/customHooks/useCardInput';
import useSelect from '../utils/customHooks/useSelect';
import useCvvInput from '../utils/customHooks/useCvvInput';
import {useHistory, useLocation} from 'react-router-dom';
import {getFromSearchString, notify, sendRequest} from '../utils/functions';
import {setInstance} from '../utils/reducers/orderAmountReducer';
import LoadPanel from '../components/LoadPanel';
import {useTranslation} from 'react-i18next';
import {useDispatch, useSelector} from 'react-redux';
import Classnames from 'classnames';
import {vars, countryList, selectStyles} from '../utils/variables';
import PhoneInput from 'react-phone-input-2';
import Select from 'react-select';
import 'react-phone-input-2/lib/plain.css';

import '../styles/Card.scss';

const {SUCCESS_API_CODE} = vars;

const Card = (props) => {
  const {paymentSystemTypes, order} = props;

  const orderAmount = useSelector((state) => state.orderAmount.instance);
  const dispatch = useDispatch();
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const urlid = getFromSearchString(location.pathname, 3);
  const paymentSystemKindID = getFromSearchString(location.pathname, 4);
  const goBack = () => {
    history.push(`/checkout/list/${urlid}`);
  };
  const [orderParams, setOrderParams] = useState(null);

  useEffect(() => {
    if (paymentSystemTypes && Array.isArray(paymentSystemTypes)) {
      const pst = paymentSystemTypes.find((item) => item.ID === parseInt(paymentSystemKindID));

      if (pst && pst.OrderParams) {
        try {
          /*console.log('pst.OrderParams');
          console.log(pst.OrderParams);
          console.log(order);*/
          const parsedData = JSON.parse(pst.OrderParams);

          const customer = [
            'CustomerEmail',
            'CustomerPhone',
            'CustomerFirstName',
            'CustomerLastName',
            'CustomerBirthDate',
          ];

          const billing = [
            'CustomerBillingAddress',
            'CustomerBillingCountry',
            'CustomerBillingCity',
            'CustomerBillingZip'
          ];

          const labels = {
            CustomerEmail: 'email',
            CustomerPhone: 'phone',
            CustomerBirthDate: 'birth_date',
            CustomerBillingZip: 'billing_zip',
            CustomerFirstName: 'first_name',
            CustomerLastName: 'last_name',
            CustomerBillingCountry: 'billing_country',
            CustomerBillingCity: 'billing_city',
            CustomerBillingAddress: 'billing_address',
          };

          const filterDataWithLabels = (keys, data, labels) => {
            return keys.map(key => {
              if (data[key]) {
                return {
                  [key]: {
                    ...data[key],
                    Label: labels[key],
                    Value: order.hasOwnProperty(key) && order[key] ? order[key] : '',
                    IsReadOnly: order.hasOwnProperty(key) && order[key],
                  }
                };
              }
              return null;
            }).filter(item => item);
          };

          const orderData = {
            customerData: filterDataWithLabels(customer, parsedData, labels),
            billingData: filterDataWithLabels(billing, parsedData, labels)
          };

          setOrderParams(orderData);
        } catch (error) {
          notify(error.message);
        }
      }
    }
  }, [paymentSystemTypes]);

  const createCardPayment = async (params) => {
    setIsLoading(true);
    const d = new Date();

    const response = await sendRequest('CheckoutOrder/CreateCardPayment', {
      Params: {
        BrowserAcceptHeader: 'text/html',
        BrowserColorDepth: window.screen.colorDepth,
        BrowserLanguage: window.navigator.language,
        BrowserScreenHeight: window.screen.height,
        BrowserScreenWidth: window.screen.width,
        BrowserTimezone: d.getTimezoneOffset(),
        BrowserUserAgent: window.navigator.userAgent,
        BrowserJavaEnabled: true,
        BrowserWindowHeight: window.innerHeight,
        BrowserWindowWidth: window.innerWidth,
        ...params
      }
    });

    if (response && response['ResponseCode'] === SUCCESS_API_CODE) {
      const {RedirectUrl} = response['Response'];
      if (RedirectUrl) {
        window.location.href = RedirectUrl;
      } else {
        window.location.hash = `/checkout/status/${params.Urlid}`;
      }
    } else {
      notify(response['ResponseText']);
    }
    setIsLoading(false);
  };

  const [cardNumber, cardNumberInput] = useCardInput({
    type: 'text',
    id: 'cardNumber',
    name: 'cardNumber',
    placeholder: '',
    autocomplete: 'cc-number',
  });

  const [cardHolder, cardHolderInput] = useInput({
    type: 'text',
    id: 'cardHolder',
    name: 'cardHolder',
    placeholder: '',
    autocomplete: 'cc-name',
  });

  const [cvv, cvvInput] = useCvvInput({
    type: 'text',
    id: 'cvv',
    name: 'cvv',
    placeholder: '',
  });

  const [isValidCvv, setIsValidCvv] = useState(true);
  const [isValidCardholder, setIsValidCardholder] = useState(true);

  const checkCvv = () => {
    return cvv.length === 3 && /^\d+$/.test(cvv);
  };

  const checkCardholder = () => {
    return /^[a-zA-Z\s]+$/.test(cardHolder);
  };

  useEffect(() => {
    if (cvv) {
      setIsValidCvv(checkCvv())
    }
  }, [cvv]);

  useEffect(() => {
    if (cardHolder) {
      setIsValidCardholder(checkCardholder())
    }
  }, [cardHolder]);

  const [policy, setPolicy] = useState(false);

  const [month, monthSelect] = useSelect({
    type: 'month',
    placeholder: '',
    autocomplete: 'cc-exp-month',
  });

  const [year, yearSelect] = useSelect({
    type: 'year',
    placeholder: '',
    autocomplete: 'cc-exp-year',
  });

  const policyClass = {
    'card-checkbox-icon': true,
    'card-checkbox-checked': policy,
  };

  const isEnabledNext = cardNumber && cardHolder && month && year && cvv && policy && checkCvv() && checkCardholder();

  useEffect(() => {
    // Check if the page is being reloaded or refreshed
    let isReload = false;

    if (performance.getEntriesByType('navigation')[0]) {
      const navigationType = performance.getEntriesByType('navigation')[0].type;

      isReload = navigationType === 'reload';

    } else {
      console.log('Navigation Timing API is not supported.');
      isReload = window.performance.navigation.type === 1;
    }

    if (isReload) {
      history.replace(`/checkout/list/${urlid}`);
    }
  }, [history]);

  useEffect(() => {
    return () => {
      dispatch(setInstance(null));
    }
  }, []);

  const [formErrors, setFormErrors] = useState({});
  const validateFields = () => {
    const errors = {};
    orderParams && Object.entries(orderParams).forEach(([sectionName, fieldsArray]) => {
      fieldsArray.forEach(fieldObj => {
        const fieldName = Object.keys(fieldObj)[0];
        const fieldProps = fieldObj[fieldName];
        const {IsMandatory} = fieldProps;

        const field = document.getElementById(fieldName);

        if (fieldName === 'CustomerBillingCountry' && IsMandatory) {
          if (countryCode && countryCode.value) {
            delete errors[fieldName];
          } else {
            errors[fieldName] = true;
          }
        } else if(fieldName === 'CustomerPhone' && IsMandatory) {
          if (phone) {
            delete errors[fieldName];
          } else {
            errors[fieldName] = true;
          }
        } else if (field && IsMandatory && !document.getElementById(fieldName).value) {
          errors[fieldName] = true;
        }
      });
    });
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    console.log('formErrors');
    console.log(formErrors);
  }, [formErrors]);

  const onInputChange = (event) => {
    const {target} = event;

    setFormErrors({...formErrors, [target.id]: !target.value});
  };

  const [phone, setPhone] = useState(order['CustomerPhone']);
  const [countryCode, setCountryCode] = useState(null);

  useEffect(() => {
    if (order['CustomerBillingCountry']) {
      const currentCountry = countryList.find((item) => item.value === order['CustomerBillingCountry']);
      setCountryCode(currentCountry);
    }
  }, [order]);

  return isLoading ? (
    <LoadPanel/>
  ) : (
    <form className={'card-wrapper'}>
      <div className={'card-form'}>
        <div className={'card-title'}>
          {`${t('card_data')}:`}
          {orderAmount && orderAmount.isDifferentCurrency ? (
            <span
              className={'sub-card-title'}
            >
              {`${t('to_be_paid')}: ${orderAmount.paymentOrderAmount.Amount} ${orderAmount.paymentOrderAmount.CurrencyCode}`}
            </span>
          ) : null}
        </div>
        <div className={'card-row'}>
          <label className={'card-label'} htmlFor="cardNumber">
            {`${t('card_number')}*`}
          </label>
          <div className={'card-input'}>
            {cardNumberInput}
          </div>
        </div>
        <div className={'card-row'}>
          <label className={'card-label'} htmlFor="cardHolder">
            {`${t('cardholder_name')}*`}
          </label>
          <div className={Classnames('card-input', {'input-validation-error': !isValidCardholder})}>
            {cardHolderInput}
            {!isValidCardholder && (
              <div className={'input-validation-error-text'}>
                {t('latin_allowed')}
              </div>
            )}
          </div>
        </div>
        <div className={'card-row card-select-wrap'}>
					<span className={'card-label'}>
						{t('expires')}
					</span>
          <div className={'card-select extra-space'}>
            {monthSelect}
          </div>
          <div className={'card-select'}>
            {yearSelect}
          </div>
        </div>
        <div className={'card-row card-select-wrap'}>
					<span className={'card-label'}>
						CVV2/CVC2/CAV2*
					</span>
          <div className={Classnames('card-input extra-space', {'input-validation-error': !isValidCvv})}>
            {cvvInput}
            {!isValidCvv && (
              <div className={'input-validation-error-text'}>
                {t('must_contain_three_digit')}
              </div>
            )}
          </div>
          <div className={'card-select'}>
            <div
              // tabIndex={0}
              onClick={() => {
                setPolicy(!policy);
              }}
              onKeyDown={({charCode}) => {
                if (charCode === 13 || charCode === 32) {
                  setPolicy(!policy);
                }
              }}
              className={'card-checkbox'}
            >
              <i className={Classnames(policyClass)}/>
              <div
                className={'card-checkbox-text'}
              >
                <span>{t('i_agree_with')}</span>
                <a
                  target={'_blank'}
                  href={'https://sharpay.net/docs/Terms_and_Conditions_eng.pdf'}
                >
                  {t('the_conditions')}
                </a>
              </div>
            </div>
          </div>
        </div>
        {orderAmount && orderAmount.isDifferentCurrency && (
          <div className={'important-message'}>
            {t('message', {
              Amount: orderAmount.paymentOrderAmount.Amount,
              CurrencyCode: orderAmount.paymentOrderAmount.CurrencyCode,
            })}
          </div>
        )}
        {orderParams ? (
          <div className={'order-params'}>
            {Object.entries(orderParams).map(([sectionName, fieldsArray]) => {
              const visibleItems = fieldsArray.map(obj => Object.values(obj)[0]).filter((item) => item.IsVisible);

              if (visibleItems.length === 0) {
                return null;
              }

              return (
                <div key={sectionName}>
                  <div className={'card-title'}>
                    {`${t(sectionName === 'customerData' ? 'contact_data' : 'billing_data')}:`}
                  </div>
                  {fieldsArray.map(fieldObj => {
                    const fieldName = Object.keys(fieldObj)[0]; // Get the field name
                    const fieldProps = fieldObj[fieldName]; // Get the field properties
                    const {IsVisible, IsMandatory, Label, Value, IsReadOnly} = fieldProps;
                    const type = fieldName === 'CustomerBirthDate' ? 'date' : 'text';

                    const inputProps = {
                      readOnly: IsReadOnly,
                      className: 'order-fields',
                      onChange: onInputChange,
                      required: IsMandatory,
                      type: type,
                      id: fieldName,
                      name: fieldName,
                    };

                    if (Value) {
                      let val = Value;

                      if (fieldName === 'CustomerBirthDate') {
                        val = new Date(Value).toISOString().slice(0, 10);
                      }

                      inputProps.value = val;
                    }

                    return IsVisible ? (
                      <div key={fieldName} className={'card-row'}>
                        <label className={'card-label'} htmlFor={fieldName}>
                          {`${t(Label)}${IsMandatory ? '*' : ''}`}
                        </label>
                        {fieldName === 'CustomerPhone' ? (
                          <div className={formErrors[fieldName] ? 'card-input input-validation-error' : 'card-input'}>
                            <PhoneInput
                              specialLabel={''}
                              value={phone}
                              placeholder={''}
                              inputProps={{
                                id: fieldName,
                                name: fieldName,
                                className: 'form-control order-fields',
                                readOnly: IsReadOnly,
                              }}
                              disableDropdown={IsReadOnly}
                              onChange={(newPhone) => {
                                setPhone(newPhone);
                              }}
                            />
                            {formErrors[fieldName] && <div
                              className={'input-validation-error-text'}>{t('field_required')}</div>}
                          </div>
                        ) : fieldName === 'CustomerBillingCountry' ? (
                          <div className={formErrors[fieldName] ? 'card-select input-validation-error' : 'card-select'}>
                            <Select
                              options={countryList}
                              styles={selectStyles}
                              isSearchable={true}
                              inputId={fieldName}
                              placeholder={t('select_country')}
                              onChange={(selectedOption) => {
                                setCountryCode(selectedOption);
                              }}
                              value={countryCode}
                              className={'country-select-container'}
                              isDisabled={!!countryCode}
                            />
                            {formErrors[fieldName] && <div
                              className={'input-validation-error-text'}>{t('field_required')}</div>}
                          </div>
                        ) : (
                          <div className={formErrors[fieldName] ? 'card-input input-validation-error' : 'card-input'}>
                            <input
                              {...inputProps}
                              />
                            {formErrors[fieldName] && <div
                              className={'input-validation-error-text'}>{t('field_required')}</div>}
                          </div>
                        )}
                      </div>
                    ) : null;
                  })}
                </div>
              );
            })}
          </div>
        ) : null}
      </div>
      <div className={Classnames('card-buttons', {
        'mobile-buttons': false
      })}>
        <div className={'logo-block'}>
          <img className={'pp-logo'} src="/img/pp-icons/verified_by_visa_logo.png" alt="visa"/>
          <img className={'pp-logo'} src="/img/pp-icons/mastercard-secure-code.png" alt="master card"/>
          <img className={'pp-logo'} src="/img/pp-icons/pcidss_logo.png" alt="PCI DSS"/>
          {parseInt(paymentSystemKindID, 10) === 22 ? (
            <img className={'pp-logo'} src="/img/pp-icons/logo-gor-black.png" alt="Platon"/>
          ) : null}
        </div>
        <div className={'buttons-block'}>
          <button
            onClick={goBack}
          >
            {t('cancel')}
          </button>
          <button
            disabled={!isEnabledNext}
            className={!isEnabledNext ? 'button-disabled' : 'button-enabled'}
            onClick={(e) => {
              e.preventDefault();

              if (validateFields()) {
                const fields = document.querySelectorAll('.order-fields');
                const fieldsParams = {};

                [...fields].forEach((field) => {
                  fieldsParams[field.id] = field.value;
                });

                const params = {
                  Urlid: urlid,
                  PaymentSystemTypeID: parseInt(paymentSystemKindID, 10),
                  CardNumber: cardNumber.replace(/\s/g, ''),
                  CardHolderName: cardHolder,
                  CardExpireDate: `${month}${year}`,
                  CardCode: cvv,
                  PaymentCurrencyID: (orderAmount && orderAmount.paymentOrderAmount.CurrencyID) || null,
                  ...fieldsParams,
                };

                if (countryCode) {
                  params.CustomerBillingCountry = countryCode.value;
                }

                createCardPayment(params).catch((e) => {
                  console.error(e);
                });
              }
            }}
          >
            {t('next')}
          </button>
        </div>
      </div>
    </form>
  );
};

export default Card;
