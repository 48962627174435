import React, {useState} from "react";

export default function useCvvInput({type, id, name, placeholder}) {
	const [value, setValue] = useState("");
	const input = <input
		id={id}
		className={'text-security'}
		maxLength={3}
		name={name}
		placeholder={placeholder}
		value={value}
		onChange={e => setValue(e.target.value)}
		type={type}
	/>;
	return [value, input];
}
