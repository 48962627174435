import React, {useState} from "react";

function cc_format(value) {
	const v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '');
	const matches = v.match(/\d{4,16}/g);
	const match = matches && matches[0] || '';
	const parts = [];

	for (let i = 0, len = match.length; i < len; i += 4) {
		parts.push(match.substring(i, i + 4))
	}

	if (parts.length) {
		return parts.join(' ');
	} else {
		return value;
	}
}

export default function useCardInput({type, id, name, placeholder, autocomplete = 'off'}) {
	const [value, setValue] = useState("");
	const input = <input
		id={id}
		name={name}
		placeholder={placeholder}
		autoComplete={autocomplete}
		value={value}
		onInput={(e) => {
			setValue(cc_format(e.target.value));
		}}
		onChange={() => {
		}}
		type={type}
	/>;
	return [value, input];
}
