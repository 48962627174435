import React, {useState} from 'react';
import SidebarContent from './SidebarContent';
import {useTranslation} from 'react-i18next';
import '../styles/sidebar.scss';

const Sidebar = (props) => {
  const {t} = useTranslation();
	const {screenSize, order} = props;
	const sidebarSizeClass = `sidebar-${screenSize}`;

	const [isOpen, toggleSidebar] = useState(false);
	const headClass = isOpen ? 'head-open' : 'head-close';

	return (
		<div className={`sidebar ${sidebarSizeClass}`}>
			<div
				className={`sidebar-accordion-head ${headClass}`}
				onClick={() => {
					toggleSidebar(!isOpen)
				}}
			>
				<div className={'sidebar-head-inner'}>
					<div>{t('payment_details')}</div>
					<div className={'details-icon'}/>
				</div>
			</div>
			{order['Amount'] && (
				<SidebarContent
					order={order}
					isOpen={isOpen}
				/>
			)}
		</div>
	);
};

export default Sidebar;
