import React, {useState} from 'react';
import {copyTextToClipboard} from '../utils/functions';
import {useTranslation} from 'react-i18next';

const CopyBlock = ({text}) => {
  const {t } = useTranslation();
  const [hintVisible, setHintVisible] = useState(false);

  const handleClick = () => {
    copyTextToClipboard(text);
    setHintVisible(true);
    setTimeout(() => {
      setHintVisible(false);
    }, 2000);
  };

  return (
    <div className={'app-copy-button-wrapper'}>
      <div onClick={handleClick} className={'copy-button'}>
        <span style={{
          opacity: hintVisible ? 1 : 0,
        }} className={'copy-button-hint'}>{t('copied')}</span>
      </div>
    </div>
  );
}

export default CopyBlock;