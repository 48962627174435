import React from 'react';
import Topbar from "../components/Topbar";
import Sidebar from "../components/Sidebar";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

const Layout = (props) => {
	const {screenSize, currentPage} = props;
	return (
		<div className={`layout ${screenSize}`}>
			<Topbar
				currentPage={currentPage}
				order={props.order}
				screen={screenSize}
			/>
			<div className={'main-content'}>
				<Sidebar
					order={props.order}
					screenSize={screenSize}
				/>
				{React.cloneElement(props.children)}
			</div>
		</div>
	);
};

export default Layout;
