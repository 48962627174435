import React from 'react';
import Loader from 'react-loader-spinner';

const LoadPanel = () => {
  return <div className={'loader'}>
    <Loader
      type="Oval"
      color="#4cd964"
      height={100}
      width={100}
    />
  </div>;
}

export default LoadPanel;