import React, {useState} from "react";

export default function useInput({type, id, name, placeholder, autocomplete = 'off'}) {
	const [value, setValue] = useState("");
	const input = <input
		id={id}
		name={name}
		placeholder={placeholder}
		value={value}
		onChange={e => setValue(e.target.value)}
		type={type}
		autoComplete={autocomplete}
	/>;
	return [value, input, setValue];
}
