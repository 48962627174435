import React from 'react';
import GooglePayButton from '@google-pay/button-react';
import {formatAmountForGooglePay, notify, s_notify} from '../utils/functions';

const Pay = ({order}) => {
  console.log(order);

  return (
    <div style={{padding: '1rem'}}>
      <h1>Pay Page</h1>
      <GooglePayButton
        environment="TEST"
        paymentRequest={{
          apiVersion: 2,
          apiVersionMinor: 0,
          allowedPaymentMethods: [
            {
              type: 'CARD',
              parameters: {
                allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
                allowedCardNetworks: ['MASTERCARD', 'VISA'],
              },
              tokenizationSpecification: {
                type: 'DIRECT',
                parameters: {
                  protocolVersion: 'ECv2',
                  publicKey: 'BHFkdP48+QR3uvTeAOIKunxdQRC5DyzpNfHCXM0Er3JIhZWCdXsOUhF8za/K7+PLB28LuVGSR2OqLq8yi2/W2g4='
                }
              }
            },
          ],
          merchantInfo: {
            merchantId: 'BCR2DN4TTXBLT5BE',
            merchantName: 'Demo Merchant',
          },
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPriceLabel: 'Total',
            totalPrice: formatAmountForGooglePay(order.Amount),
            //totalPrice: '1.00',
            currencyCode: order.CurrencyCode,
            //countryCode: 'UA',
          },
        }}
        onLoadPaymentData={paymentRequest => {
          console.log('load payment data', paymentRequest);
          const token = paymentRequest.paymentMethodData.tokenizationData.token;

          if (token) {
            s_notify('Token Received');
          } else {
            notify('Error');
          }
        }}
      />
    </div>
  );
};

export default Pay;