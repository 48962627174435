import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import Summary from '../components/Summary';
import {checkOrder, fetchCheckoutOrder, sendRequest, notify, getFromSearchString} from '../utils/functions';
import {useHistory, useLocation} from 'react-router-dom';
import {vars} from '../utils/variables';

import '../styles/Status.scss';

const {SUCCESS_API_CODE, STATUS: {SUCCESS, SECOND_SUCCESS, FAILURE, PENDING, ICONS}} = vars;

const green = '#23ce6b';
const yellow = '#ffcb00';
const red = '#ff0000';

const getColorByStatus = (statusId) => {
	switch (statusId) {
		case SUCCESS:
		case SECOND_SUCCESS:
			return green;
		case FAILURE:
			return red;
		case PENDING:
		default:
			return yellow;
	}
};
const getIconByStatus = (statusId) => {
	return `img/icons/${ICONS[statusId]}`;
};

const Status = (props) => {
  const {t} = useTranslation();
	const {
		order: {
			Urlid,
      MerchantReturnUrl,
		}
	} = props;
  const location = useLocation();
  const history = useHistory();

	const [status, setStatusResponse] = useState({
		statusId: PENDING,
		count: 0,
    name: null,
	});

	const [lastCount, setLastCount] = useState(0);
	const [merchantReturnUrl, setMerchantReturnUrl] = useState(MerchantReturnUrl);
	const [recheckedOrder, setRecheckedOrder] = useState(props.order);
	const color = getColorByStatus(status.statusId);
	const icon = getIconByStatus(status.statusId);

  const recheckOrder = async () => {
    await checkOrder(Urlid, (orderData) => {
      const params = new URLSearchParams(location.search);

      if (params.get('from')) {
        const id = parseInt(getFromSearchString(location.pathname, 4));
        setRecheckedOrder({
          ...orderData,
          PaymentSystemTypeID: id,
          PaymentSystemTypeName: params.get('from'),
        });
      } else {
        setRecheckedOrder(orderData);
      }

      setMerchantReturnUrl(orderData.MerchantReturnUrl);
    });
  };

	useEffect(() => {
		fetchCheckoutOrder(Urlid, setStatusResponse, PENDING);

		recheckOrder();
	}, []);

	useEffect(() => {
		if (status.statusId > 0) {
			setLastCount(status.count);
		}
	}, [recheckedOrder, status]);

	useEffect(() => {
		if (status.statusId !== PENDING) {
			recheckOrder();
		}

		if (lastCount !== status.count) {
			recheckOrder();
		}

	}, [status]);

	return (
		<div key={`${status.statusId}-${status.count}`} className={'status-wrapper'}>
			<div className={'status-head'}>
				<div className={'status-title'}>{`${t('status')}:`}
					<div className={'status-text'}>
            {status.name ? status.name : recheckedOrder.MerchantOrderStatusName}
					</div>
					<div className={'status-icon'}>
						<img className={status.statusId === PENDING ? 'clockwiseSpin': ''} src={icon} alt=''/>
					</div>
				</div>
			</div>
      <Summary
        Payments={recheckedOrder.Payments}
        PaymentForeignAmount={recheckedOrder.PaymentForeignAmount}
        TotalAmount={recheckedOrder.PaymentAmount}
        FeeAmount={recheckedOrder.PaymentFeeAmount}
        CurrencyRate={recheckedOrder.PaymentCurrencyRate}
        PaymentSystemTypeID={recheckedOrder.PaymentSystemTypeID}
        PaymentSystemTypeName={recheckedOrder.PaymentSystemTypeName}
        BaseStatusText={recheckedOrder.BaseStatusText}
        InfoUrlText={recheckedOrder.InfoUrlText}
        InfoUrl={recheckedOrder.InfoUrl}
        iconPath={'/img/SharPay-Label.svg'}
        border={`2px solid ${color}`}
      />
			<div className={'status-button'}>
				<button
          title={merchantReturnUrl}
					disabled={!merchantReturnUrl}
					onClick={() => {
						if (merchantReturnUrl) {
              window.location.href = merchantReturnUrl;
						} else {
              notify(t('link_not_provided'));
						}
					}}>
          {t('to_the_store')}
				</button>
			</div>
		</div>
	);
};

export default Status;
