import React from 'react';
import classNames from 'classnames/bind';
import {useTranslation} from 'react-i18next';

const PreRequisitesScreen = ({onCancel, onConfirm, isMobile}) => {
  const {t} = useTranslation();

  return (
    <div className={'requisites-wrapper'}>
      <div className={'requisites-text'}>
        <div className={'text-block'}>
          <p>{t('r1')}</p>
          <p>{t('r2')}</p>
        </div>
        <div className={'text-block'}>
          <p>{t('r3')}</p>
          <p>{t('r4')}</p>
        </div>
        <div className={'text-block'}>
          <p>{t('r5')}</p>
          <p>{t('r6')}</p>
          <p>{t('r7')}</p>
          <p>{t('r8')}</p>
        </div>
        <div className={'text-block'}>
          <p>{t('r9')}</p>
        </div>
        <div className={'text-block'}>
          <p>{t('r10')}</p>
        </div>
      </div>
      <div className={classNames('accounts-buttons', {
        'mobile-buttons': isMobile,
      })}>
        <button
          onClick={onCancel}
        >
          {t('cancel')}
        </button>
        <button
          className={'button-enabled'}
          onClick={onConfirm}
        >
          {t('next')}
        </button>
      </div>
    </div>
  );
}

export default PreRequisitesScreen;