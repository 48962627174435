import React from 'react';
import ReactDOM from 'react-dom';
import {HashRouter} from 'react-router-dom';
import LoadPanel from './components/LoadPanel';
import App from './App';
import {Provider} from 'react-redux';
import store from './utils/store';
import './utils/i18n';
import './index.css';

const APP_HASH = process.env.REACT_APP_HASH;

if (typeof localStorage.APP_HASH === 'undefined' || localStorage.APP_HASH === null) {
  localStorage.setItem('APP_HASH', APP_HASH);
}

if (localStorage.APP_HASH !== APP_HASH) {
  localStorage.clear();
}

ReactDOM.render(
  <React.Suspense fallback={<LoadPanel/>}>
    <Provider store={store}>
      <HashRouter>
        <App/>
      </HashRouter>
    </Provider>
  </React.Suspense>,
	document.getElementById('root')
);
