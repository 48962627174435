import React, {useEffect, useState} from 'react';
import {getFromSearchString, notify, sendRequest} from '../utils/functions';
import CopyBlock from '../components/CopyBlock';
import {useLocation, useHistory} from 'react-router-dom';
import LoadPanel from '../components/LoadPanel';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {vars} from '../utils/variables';
import QRCode from 'react-qr-code';

import '../styles/SharpayAccounts.scss';
import '../styles/Crypto.scss';

const {SUCCESS_API_CODE, STATUS} = vars;

const ByRequisites = (props) => {
  const orderAmount = useSelector((state) => state.orderAmount.instance);
  const {t} = useTranslation();
  const {
    order,
  } = props;
  const {Urlid} = order;
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const history = useHistory();
  const id = getFromSearchString(location.pathname, 4);
  const [systemName, setSystemName] = useState('');

  const [bankDetails, setBankDetails] = useState([]);

  useEffect(() => {
    if (isLoading) {
      getPaymentSystemTypes().then((response) => {
        const bankDetails = response.BankDetails || [];
        setBankDetails(bankDetails);
        setSystemName(response.Name);
      }).catch((error) => {
        notify(error);
      }).finally(() => {
        setIsLoading(false);
      });
    }
  }, []);

  const getPaymentSystemTypes = async () => {
    const params = await sendRequest('CheckoutOrder/GetPaymentSystemTypes', {
      Params: {
        Urlid: Urlid,
        PaymentSystemTypeID: parseInt(id),
      }
    });

    if (params.ResponseCode === SUCCESS_API_CODE) {
      return params.Response.PaymentSystemType[0];
    } else {
      throw new Error(params.ResponseText);
    }
  }

  if (isLoading) {
    return (<LoadPanel/>);
  }

  const generateQr = (qrItem) => {
    if (qrItem) {
      return (
        <div className={'req-qr'}>
          <span>{t('scan_qr')}</span>
          <QRCode value={qrItem.Value} size={150}/>
        </div>
      );
    }

    return null;
  };

  const handleRedirect = () => {
    const currentUrl = location.pathname;
    const searchParams = new URLSearchParams(location.search);
    searchParams.set('from', systemName);
    const newPath = currentUrl.replace('/checkout/requisites/', '/checkout/status/');

    const newUrl = `${newPath}?${searchParams.toString()}`;

    history.push(newUrl);
  }

  return (
    <div className={'req-wrapper'}>
      <div className='req-title'>
        {t('bank_data')}:
        {orderAmount && orderAmount.isDifferentCurrency ? (
          <span
            className={'sub-card-title'}
          >
              {`${t('to_be_paid')}: ${orderAmount.paymentOrderAmount.Amount} ${orderAmount.paymentOrderAmount.CurrencyCode}`}
            </span>
        ) : null}
      </div>
      <div className={'req-inner'}>
        {bankDetails.map((item, index) => {
          const reqList = item[Object.keys(item)[0]];

          if (!reqList) return null;

          const result = reqList.reduce((acc, obj) => {
            if (obj.Name.replace(':', '').toLowerCase().includes('qr')) {
              acc.qrObject = obj;
            } else {
              acc.rest.push(obj);
            }
            return acc;
          }, { qrObject: null, rest: [] });

          return (
            <div key={`item-${index}`} className={'req-item'}>
              <span className={'req-item-title'}>{Object.keys(item)[0]}</span>
              {result.rest.map((item, index) => {
                return (
                  <div key={`item-row-${index}`} className={'req-row'}>
                    <span className={'req-name'}>{item.Name}</span>
                    <div className={'req-value'}>
                      <span>{item.Value}</span>
                      <CopyBlock text={item.Value}/>
                    </div>
                  </div>
                );
              })}

              {generateQr(result.qrObject)}

              {index < bankDetails.length - 1 ? (
                <div className={'divider'}></div>
              ) : null}
            </div>
          );
        })}

      </div>
      <div className={'req-action'}>
        <button className={'app-btn'} onClick={handleRedirect}>{t('i_paid')}</button>
      </div>
    </div>
  );
};

export default ByRequisites;
